export const mobileNav = () => {
  const header = document.querySelector('header.header');
  const mobileNav = document.querySelector('.mobile-menu');
  const headerHeight = header.offsetHeight;
  const toggles = document.querySelectorAll('.menu-toggle');

  toggles.forEach((toggle) =>
    toggle.addEventListener('click', () => {
      mobileNav.classList.toggle('open');
      document.body.classList.toggle('fixed');
    })
  );

  mobileNav.style.top = `${headerHeight}px`;
  mobileNav.style.height = ` calc(100% - ${headerHeight}px)`;
  document.body.style.paddingTop = header.offsetHeight + 'px';
};
