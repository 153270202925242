export const navigation = () => {
  const header = document.querySelector('.header');
  const navItems = [...document.querySelectorAll('.header__nav-link')];
  const menuSlider = document.querySelector('.header__nav-slider');
  const submenu = document.querySelector('.submenu');
  const submenuItems = [...document.querySelectorAll('.header__nav-link[data-target]')];
  const submenuMenus = [...document.querySelectorAll('[data-submenu]')];

  // Set initial positions
  submenuItems.forEach((submenuItem) => {
    let target = submenuItem.getAttribute('data-target');
    let submenu = document.querySelector(`[data-submenu=${target}]`);
    if (typeof submenu !== null) {
      let submenuPos = submenuItem.getBoundingClientRect();
      submenu.style.marginLeft = submenuPos.left + 'px';
    }
  });

  // Set submenu top position based on header height
  submenu.style.top = header.offsetHeight + 'px';
  document.body.style.paddingTop = header.offsetHeight + 'px';

  navItems.forEach((navItem) => {
    navItem.addEventListener('mouseover', (e) => {
      // Reset submenu menus
      submenuMenus.forEach((menu) => {
        menu.style.display = 'none';
      });

      let sliderWidth = e.target.offsetWidth;
      let sliderLeft = e.target.offsetLeft;
      menuSlider.setAttribute('style', `width:${sliderWidth}px;left:${sliderLeft}px;`);
      submenu.classList.remove('is-open');

      if (e.target.hasAttribute('data-target')) {
        submenu.classList.add('is-open');
        let target = e.target.getAttribute('data-target');
        let submenuTarget = document.querySelector(`[data-submenu=${target}]`);
        submenuTarget.style.display = 'flex';
        submenuTarget.classList.add('fade-in');
      }
    });
  });

  submenu.addEventListener('mouseleave', () => {
    submenu.classList.remove('is-open');
  });
};
