'use strict';

import { navigation } from './includes/navigation';
import { mobileNav } from './includes/mobile-nav';
import { carousel } from './includes/carousel';
import { slider } from './includes/slider';
import { caseFilters } from './includes/case-filters';
require('fslightbox');

document.addEventListener('DOMContentLoaded', () => {
  init();
  window.addEventListener('resize', () => init());
  if (document.querySelectorAll('.cases-slider-filters__item').length > 0) caseFilters();
  if (window.fsLightbox) fsLightbox.props.loadOnlyCurrentSource = true;
});

const init = () => {
  window.innerWidth > 768 ? navigation() : mobileNav();
  carousel();
  slider();
};
