import Swiper, { Scrollbar } from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const slider = () => {
  let spv = 4.5;

  if (window.innerWidth < 768) {
    spv = 1.5;
  }
  if (window.innerWidth > 768) {
    spv = 3.5;
  }
  if (window.innerWidth > 1600) {
    spv = 5.5;
  }
  if (window.innerWidth > 1900) {
    spv = 7.5;
  }

  const industriesSwiper = document.querySelector('.industries-slider-block');
  if (industriesSwiper && window.innerWidth > 1600) {
    let industriesSwiperSlides = [...industriesSwiper.querySelectorAll('.swiper-slide')];
    spv = industriesSwiperSlides.length;
  }

  new Swiper('.slider', {
    slidesPerView: spv,
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      dragSize: 80,
    },
    preventClicks: true,
    preventClicksPropagation: false,
    modules: [Scrollbar],
  });
};
