import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

import 'swiper/swiper-bundle.min.css';

export const carousel = () => {
  const swipers = document.querySelectorAll('.swiper');

  for (let i = 0; i < swipers.length; i++) {
    swipers[i].classList.add('swiper-container-' + i);

    Swiper.use(Autoplay);
    new Swiper('.swiper-container-' + i, {
      speed: 1200,
      autoplay: {
        delay: i > 0 ? 4000 : 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },

      modules: [Navigation, Pagination],
    });
  }
};
