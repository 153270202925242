export const caseFilters = () => {
  const filters = document.querySelectorAll('.cases-slider-filters__item');
  const caseTitle = document.querySelector('.slider__title');
  const caseBlocks = document.querySelectorAll('.cases-slider-block__item');
  const swiper = document.querySelector('.slider').swiper;

  filters.forEach((filter) => {
    filter.addEventListener('click', (e) => {
      filters.forEach((filter) => filter.classList.remove('active'));
      e.target.classList.add('active');
      caseTitle.innerText = e.target.innerText;

      let selectedFilter = e.target.dataset.industry;

      caseBlocks.forEach((caseBlock) => {
        let caseIndustries = JSON.parse(caseBlock.dataset.industries);

        if (selectedFilter == 'all') {
          caseBlock.style.display = 'block';
          swiper.update();

          return;
        }

        if (caseIndustries.includes(selectedFilter)) {
          caseBlock.style.display = 'block';
        } else {
          caseBlock.style.display = 'none';
        }

        // Update swiper
        swiper.update();
      });
    });
  });
};
